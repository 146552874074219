@tailwind base;
@tailwind components;
@tailwind utilities;

.noto-sans-gfont {
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }

h1{
    @apply font-bold my-4 text-4xl block
}
h2{
    @apply font-normal my-2 text-3xl block
}
h3{
    @apply font-light my-2 text-2xl block
}
input{
    @apply border-b-2 border-slate-300 p-2 mb-2
}
img{
    @apply w-full
}
li{
    @apply list-none
}

body{
    @apply bg-slate-100;
    font-family: Arial, sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield; /* For Firefox */
}