.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: dot-blink 1.2s infinite;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  .dot:nth-child(4) {
    animation-delay: 0.6s;
  }
  
  .dot:nth-child(5) {
    animation-delay: 0.8s;
  }
  
  @keyframes dot-blink {
    0%, 100% {
      background-color: #4a5568; /* dark color */
    }
    50% {
      background-color: #a0aec0; /* light color */
    }
  }
  